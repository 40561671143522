/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import accounting from 'accounting'
import dayjs from 'dayjs'
import { css } from '@emotion/react'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import logo from './logo-postnl.svg';
import truck from './truck.svg';
import calendar from './calendar.svg';
import calendarPup from './calendar-pup.svg';
import truckPup from './truck-pup.svg';
import beFlag from './BE-flag.svg';
import nlFlag from './NL-flag.svg';
import "@fontsource/open-sans";
import './reset.css';
import './App.css';

function App() {
	const urlParams = new URLSearchParams(window.location.search);
	const token = urlParams.get('token');

	const [selectedDeliveryOption, setSelectedDeliveryOption] = useState()
	const deliveryOptionsQuery = useQuery(['deliveryOptions', token], () =>
		fetch(`${process.env.REACT_APP_API_URL}/api/v3/Orders/GetDeliveryOptions?token=${token}`).then(async res => {
			const body = await res.json();
			if (!res.ok) {
				throw new Error(res.statusText, { cause: body })
			}
			return body
		}), {
			enabled: !!token,
			retry: false,
			refetchOnWindowFocus: false
		}
	)

	useEffect(() => {
		if (deliveryOptionsQuery.isSuccess) {
			setSelectedDeliveryOption(deliveryOptionsQuery.data.deliveryOptions.find(o => o.recommended).shippingLabelOfferId)
		}
	}, [deliveryOptionsQuery.isSuccess, deliveryOptionsQuery.data?.deliveryOptions])


	let error
	if (!token) {
		error = 'Error: missing required token parameter'
	}
	else if (deliveryOptionsQuery.isLoading) {
		return (
			<div css={css`${containerStyle}; text-align: center;`}>
				Loading…
			</div>
		)
	}
	else if (deliveryOptionsQuery.isError) {
		if (deliveryOptionsQuery.error.cause.ExceptionMessage.match(/Signature validation failed|must have three segments|Unable to decode the payload/)) {
			error = 'Error: invalid token'
		}
		else if (deliveryOptionsQuery.error.cause.ExceptionMessage.match(/Lifetime validation failed/)) {
			error = 'Error: token expired'
		}
		else if (deliveryOptionsQuery.error.cause.ExceptionMessage.match(/Request contains order item id\(s\) that are shipped already/)) {
			error = 'Error: already shipped'
		}
		else {
			error = `Error: ${deliveryOptionsQuery.error.message} - ${JSON.stringify(deliveryOptionsQuery.error.cause)}`
		}
	}

	if (error) {
		return (
			<div css={css`${containerStyle}; text-align: center;`}>
				{error}
			</div>
		)
	}

	const isPickupPoint = deliveryOptionsQuery.data.order.pickupPoint === true
	const flag = deliveryOptionsQuery.data.order.billingDetails.countryCode === 'BE' ? (
		<img src={beFlag} width="16" alt="BE vlag" />
	) : (
		<img src={nlFlag} width="16" alt="NL vlag" />
	)
	const isEvening = deliveryOptionsQuery.data.order.orderItems[0].fulfilment.timeFrameType === 'EVENING'

	return (
		<div css={containerStyle}>
			<h1>Bestelling</h1>
			<div css={css`
				margin-bottom: 10px;
			`}>
				<span css={css`
					display: flex;
					column-gap: 10px;
					align-items: baseline;
				`}>
					{deliveryOptionsQuery.data.order.orderId}
					<span css={css`
						font-size: 13px;
						color: #828282;
					`}>
						{dayjs(deliveryOptionsQuery.data.order.orderPlacedDateTime).format('D MMMM [om] HH:mm')}
					</span>
					{flag}
				</span>
				<span>
					{deliveryOptionsQuery.data.order.billingDetails.firstName + ' ' + deliveryOptionsQuery.data.order.billingDetails.surname}
				</span>
			</div>

			{deliveryOptionsQuery.data.order.orderItems.map(i => (
				<div key={i.orderItemId} css={css`
					border: 1px solid #e6e6e6;
					padding: 10px 15px;
					border-radius: 5px;
					margin-bottom: 10px;
					overflow: hidden;
					max-width: 850px;
					@media (min-width: 480px) {
						padding: 15px 20px;
					}
					@media (min-width: 768px) {
						padding: 20px 25px;
					}
				`}>
					<h3 css={css`
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					`}>
						{i.product.title}
					</h3>
					<div css={detailsStyle}>
						<span css={grayTextStyle}>
							EAN:
						</span>
						<a href={`https://partner.bol.com/sdd/product-content/product/${i.product.ean}`} css={css`
							${detailsStyle};
							color: rgb(51, 51, 51);
							text-decoration: none;
							:hover {
								text-decoration: underline;
							}
						`} target="_blank" rel="noreferrer">
							<span>{i.product.ean}</span>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 24 24">
								<path fill="#000" d="M22.186 2.868a1.718 1.718 0 00-1.618-1.154h-3.291a.857.857 0 100 1.715h2.082l-5.204 5.204 1.213 1.212L20.57 4.64v2.082a.857.857 0 101.714 0V3.269a.854.854 0 00-.099-.401z" />
								<path fill="#000" d="M3.428 3.429H12v1.714H5.141v13.714h13.715V12h1.714v8.571H3.428V3.43z" />
							</svg>
						</a>
						<span css={grayTextStyle}>|</span>
						<span css={grayTextStyle}>{i.quantity} stuk</span>
						<span css={grayTextStyle}>|</span>
						<span css={grayTextStyle}>{accounting.formatMoney(i.unitPrice)}</span>
					</div>
				</div>
			))}

			<h2>Verzendzegels</h2>
			<div css={css`
				display: flex;
				flex-direction: column;
				row-gap: 10px;
			`}>
				{deliveryOptionsQuery.data.deliveryOptions.map(o => {
					let alertIcon
					if (isPickupPoint) {
						let content
						if (isPickupPoint) {
							content = (
								<div css={css`
									color: #828282;
									font-size: 12px;
									padding: 16px;
									text-align: left;
								`}>
									Alleen pakketzegel mogelijk omdat de klant voor levering op een Afhaalpunt koos. Dat kan alleen voor Pakketten. <a href="https://partnerplatform.bol.com/nl/verborgen/nieuw-bij-verzenden-via-bol-com-afhaalpunten-levering/" target="_blank" rel="noreferrer">Klik hier voor meer informatie</a>.
								</div>
							)
						}
						else if (true == false) {
							content = (
								<div css={css`
									color: #828282;
									font-size: 12px;
									padding: 16px;
									text-align: left;
								`}>
									De klant heeft voor maandaglevering gekozen, hierdoor is het niet mogelijk om voor een brievenbuszending te kiezen. <a href="https://partnerplatform.bol.com/nl/hulp-nodig/bestellen-en-verzenden/verzenden-via-bol-com/" target="_blank" rel="noreferrer">Kijk hier voor meer informatie</a>.
								</div>
							)
						}
						else if (isEvening) {
							content = (
								<div css={css`
									color: #828282;
									font-size: 12px;
									padding: 16px;
									text-align: left;
								`}>
									De klant heeft voor avondlevering gekozen, <a href="https://partnerplatform.bol.com/nl/verborgen/verzenden-via-bol-com-uitgebreid-met-avondlevering/" target="_blank" rel="noreferrer">kijk hier voor meer informatie.</a>.
								</div>
							)
						}

						alertIcon = (
							<Tippy
								theme="light"
								interactive={true}
								maxWidth={190}
								content={content}
							>
								<svg width="17" height="17" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
									<path fill="#828282" d="M11.154 10.34a.857.857 0 011.714-.017l.04 4.24a.857.857 0 01-1.714.017l-.04-4.24zM12.006 18.857a.857.857 0 100-1.714.857.857 0 000 1.714z" />
									<path fill="#828282" fillRule="evenodd" d="M24 22.286L12 1.714 0 22.286h24zm-3-1.715L12 5.143 3 20.57h18z" />
								</svg>
							</Tippy>
						)
					}

					let icon
					let iconWidth
					if (dayjs(o.handoverDetails.latestHandoverDateTime).diff(dayjs(), 'day') > 0) {
						icon = isPickupPoint ? calendarPup : calendar
					}
					else {
						icon = isPickupPoint ? truckPup : truck
					}
					iconWidth = isPickupPoint ? 30 : 20

					let latestHandoverLabel
					const date = dayjs(o.handoverDetails.latestHandoverDateTime)
					if (date.diff(dayjs(), 'day') === 0) {
						latestHandoverLabel = 'Vandaag'
					}
					else {
						latestHandoverLabel = 'Op ' + date.format('D MMMM')
					}

					return (
						<div key={o.shippingLabelOfferId} css={css`
							display: grid;
							grid-template-columns: 20px 30px 1fr max-content 130px;
							column-gap: 20px;
							align-items: center;
							cursor: pointer;
							transition: border-color .3s ease-out;
							border: 1px solid ${selectedDeliveryOption === o.shippingLabelOfferId ? '#0000a4' : '#e6e6e6'};
							border-radius: 5px;
							@media (min-width: 480px) {
								padding: 15px 20px;
							}
							@media (min-width: 768px) {
								padding: 20px 25px;
							}
						`} onClick={() => setSelectedDeliveryOption(o.shippingLabelOfferId)}>
							<div></div>
							<img src={logo} width="30" height="30" alt="PostNL Logo" />
							<div>
								<h3>{o.labelDisplayName}</h3>
								<div css={detailsStyle}>
									<span css={grayTextStyle}>&lt; {o.packageRestrictions.maxWeight}</span>
									<span css={grayTextStyle}>|</span>
									<span css={grayTextStyle}>&lt; {o.packageRestrictions.maxDimensions}</span>
								</div>
							</div>
							<div css={css`
								display: flex;
								column-gap: 5px;
								align-items: center;
							`}>
								{alertIcon}
								{accounting.formatMoney(o.labelPrice.totalPrice)}*
							</div>
							<div css={css`
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: center;
								text-align: center;
								max-width: 120px;
								margin: auto;
								row-gap: 5px;
							`}>
								<img src={icon} width={iconWidth} alt="PostNL Truck" />
								<div>
									{latestHandoverLabel}
								</div>
								<div css={css`
									color: #828282;
									font-size: 12px;
								`}>
									Wegbrengen tot
									<div css={css`
										display: flex;
										column-gap: 5px;
										align-items: center;
										justify-content: center;
									`}>
										{dayjs(o.handoverDetails.latestHandoverDateTime).format('HH:mm')}
										<Tippy
											theme="light"
											interactive={true}
											maxWidth={190}
											content={
												<div css={css`
													color: #828282;
													font-size: 12px;
													padding: 16px;
													text-align: left;
												`}>Zorg ervoor dat je dit pakketje op tijd naar een <strong>afleverpunt</strong> brengt. Controleer de tijden op: <a href="https://www.postnl.nl/zakelijke-oplossingen/locatiewijzer" target="_blank" rel="noreferrer">www.postnl.nl/zakelijke-oplossingen/locatiewijzer</a>.</div>
											}
										>
											<svg width="13" height="13" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
												<path fill="#828282" d="M11.149 10.291a.857.857 0 111.714-.011l.045 6.857a.857.857 0 11-1.714.012l-.045-6.858zM12.054 7.714a.857.857 0 100-1.714.857.857 0 000 1.714z" />
												<path fill="#828282" fillRule="evenodd" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm-.057-1.714c5.68 0 10.286-4.605 10.286-10.286 0-5.68-4.605-10.286-10.286-10.286C6.263 1.714 1.657 6.32 1.657 12c0 5.68 4.605 10.286 10.286 10.286z" clipRule="evenodd" />
											</svg>
										</Tippy>
									</div>
								</div>
							</div>
						</div>
					)
				})}
			</div>

			<p css={css`
				${grayTextStyle};
				margin-top: 10px;
			`}>
				* Excl. eventueel verschuldigde btw. De zegelkosten verrekent bol.com automatisch met je opbrengst. 
			</p>

			<p css={css`
				margin-top: 20px;
			`}>
				Let op: Bij elke download wordt een nieuwe verzendzegel gegenereerd en geregistreerd.
				<br/>
				Zorg dat alleen de zegel die als laatste gedownload is gebruikt wordt voor deze bestelling.
				<br/>
				<a download href={`${process.env.REACT_APP_API_URL}/api/v3/Orders/DownloadShippingLabel?token=${token}&shippingLabelOfferId=${ selectedDeliveryOption }`}>Download verzendzegel</a>
			</p>
		</div>
	);
}

const containerStyle = css`
	padding: 50px 30px;
	max-width: 900px;
	margin: auto;
`
const grayTextStyle = css`
	color: #828282;
`
const detailsStyle = css`
	display: flex;
	column-gap: 5px;
	align-items: center;
`

export default App;